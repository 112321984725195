import * as React from 'react'
import styled from 'styled-components'

import Layout from 'layouts/main'
import axios from 'axios'
import { config } from '../config'
import { SCREEN } from 'styles/screens'
import { FixedContainer } from '../components/styled/containers'
import queryString from 'query-string'

const Container = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  @media only screen and (max-width: ${SCREEN.MOBILE}) {
    padding: 16px;
  }
`

const IndexPage = () => {
  const [verified, serVerified] = React.useState(0)

  React.useEffect(async () => {
    try {
      const { token } = queryString.parse(window.location.search)
      let user = (
        await axios.post(`${config.API_BASE_URL}/users/verify`, { token })
      ).data
      serVerified(1)
    } catch (e) {
      serVerified(2)
    }
  }, [])

  return (
    <Layout title="Verify">
      <Container>
        <FixedContainer>
          <p>
            {verified === 0 && 'Verifying, please wait..'}
            {verified === 1 && 'Your email has been successfully verified :)'}
            {verified === 2 && 'We were unable to verify the email address :('}
          </p>
        </FixedContainer>
      </Container>
    </Layout>
  )
}

export default IndexPage
